<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <div
        v-for="item in items"
        :key="item.name"
      >
        <v-list-group
          v-if="item.items"
          :prepend-icon="item.icon"
          no-action
          link
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.name"
            color="primary"
            @click="switchLanguage(subItem.locale)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="subItem.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          v-else
          :to="{ name: item.name}"
          :exact="item.title === 'Home'"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    methods: {
      switchLanguage (lang) {
        let url = this.$route.path
        // console.log('原来的url = ' + url)

        // 获取域名的第一个部分，即语言项
        let currentLang = url.split('.')[0]
        // 如果域名以www开头，就把当前语言设为en
        if (currentLang === 'www') {
          currentLang = 'en'
        }

        // 把链接地址按照"/"分割成数组
        const urlArray = url.split('/')
        // 如果当前语言是英文，且选择的语言不是英文，替换域名为语言项加上.olapdb.com
        if (currentLang === 'en' && lang !== 'en') {
          urlArray[0] = lang + '.olapdb.com'
        } else if (currentLang !== 'en' && lang === 'en') {
          urlArray[0] = 'www.olapdb.com'
        } else if (currentLang !== 'en' && lang !== 'en') {
          // 如果当前语言和选择的语言都不是英文，替换域名为新的语言项加上.olapdb.com
          urlArray[0] = lang + '.olapdb.com'
        }
        // 把数组按照"/"连接成字符串
        url = 'https://' + urlArray.join('/')
        // 跳转到新的链接地址
        // window.location.href = url
        // this.$router.push(url)
        location.href = url

        // console.log('现在的url = ' + url)
      },
    },

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
